import { ProjectDescription } from './ProjectDescription';
import { ProjectGallery } from './ProjectGallery';
import { SimilarProjects } from './SimilarProjects';

export const PROJECT_PATH = ['/projects/:id/:name', '/projects/:id/:name/gallery', '/projects/:id/:name/similar'];

export const PROJECTS_ROUTES = [
	{ path: PROJECT_PATH[0], component: ProjectDescription, exact: true },
	{ path: PROJECT_PATH[1], component: ProjectGallery, exact: true },
	{ path: PROJECT_PATH[2], component: SimilarProjects, exact: true },
];
