import { BackButton, Grid, Page } from 'components';
import { ProjectCard } from 'components/ProjectCard/ProjectCard';
import { useGlobalState } from 'modules/StateProvider/StateProvider';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'state/LanguageContext/LanguageContext';

export const ProjectsPage: FunctionComponent = () => {
	const { projects } = useGlobalState();
	return (
		<Page className="flex flex-col  px-4 sm:px-14 md:px-24 lg:px-32 xl:px-48 pt-12">
			<header className="w-full">
				<BackButton
					labelId="common__back-button"
					theme="dark"
					hideArrow
					className="font-bebas text-2xl leading-loose py-4 md:py-6"
				/>
				<h1 className="text-5xl md:text-6xl font-bebas py-4 md:py-6">
					<FormattedMessage id="projects__title" />
				</h1>
			</header>
			<Grid
				items={projects.filter((project) => project.projectsPage)}
				render={ProjectCard}
				sm={12}
				md={6}
				xl={6}
				xxl={4}
			/>
		</Page>
	);
};
