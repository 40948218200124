import React, { FunctionComponent } from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';
import firebase from 'firebase';
import { NotFound } from 'modules/NotFound/NotFound';

export type CustomRouteProps = RouteProps & { private?: boolean };

interface RouterWrapperProps {
	/** A list of routes to be rendered in the Switch element */
	routes: CustomRouteProps[];
}

export const PrivateRoute: FunctionComponent<{ route: CustomRouteProps }> = ({ route }) => {
	if (firebase.auth().currentUser) {
		return <Route {...route} />;
	}
	return <NotFound />;
};

export const RouterWrapper: FunctionComponent<RouterWrapperProps> = ({ routes }) => {
	return (
		<Switch>
			{routes.map((route, index) =>
				route.private ? <PrivateRoute key={index} route={route} /> : <Route key={index} {...route} />
			)}
		</Switch>
	);
};
