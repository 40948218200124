import { createContext, useContext } from 'react';
import { Project } from '../types';

interface IProjectContext {
	project: Project;
	previousProject?: Project;
	nextProject?: Project;
}

export const ProjectContext = createContext<IProjectContext>({ project: {} as Project });

export const useProject = () => useContext(ProjectContext);
