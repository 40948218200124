import classNames from 'classnames';
import { Button } from 'components';
import { getProjectLink } from 'helpers/getProjectLink';
import { Project } from 'modules/Projects/types';
import React, { PropsWithChildren } from 'react';

import { Parallax } from 'react-parallax';
import { Link } from 'react-router-dom';
import { useLang } from 'state/LanguageContext/LanguageContext';

interface LandingPageSectionProps {
	project: Project;
	index?: number;
	landing?: boolean;
}

export const LandingPageSection = ({ project, index = 0, landing }: PropsWithChildren<LandingPageSectionProps>) => {
	const { getMessage } = useLang();
	return (
		<Parallax
			bgImage={project.photoUrl}
			bgImageStyle={{ opacity: 0.5 }}
			className="fade-in"
			strength={200}
			renderLayer={(percentage) => {
				const translateFactor = (index + 1) % 2 === 1 ? 1 : -1;
				return (
					<div
						id={project.id}
						className="absolute min-h-full max-w-full py-32 w-1200 left-0 right-0 flex flex-column items-center mx-auto justify-center z-10 pr-6 sm:pr-36 md:pr-52 pl-6 sm:pl-16"
					>
						<div className={classNames(!landing && 'text-center', '')}>
							<h2
								className="text-white font-bebas text-5xl md:text-6xl"
								style={{ transform: `translateX(${(percentage - 1) * translateFactor * 60}px)` }}
							>
								{project.title}
							</h2>
							<p className="font-cera text-lg md:text-xl text-white font-light mt-4">
								{landing ? project.description : project.address}
							</p>
							{!landing && (
								<Link to={getProjectLink(project)}>
									<Button label={getMessage('see-more')} className="mt-12" />
								</Link>
							)}
							{landing && (
								<div className="text-center">
									<Link to={'projects'}>
										<Button label={getMessage('discover')} className="mt-12" />
									</Link>
								</div>
							)}
						</div>
					</div>
				);
			}}
		>
			<div className="h-screen"></div>
		</Parallax>
	);
};
