import firebase from 'firebase';
import { useMemo } from 'react';
import { useLang } from 'state/LanguageContext/LanguageContext';
import { useFetcher } from './useFetcher';
import { useLanguageResponseParser } from './useLanguageResponseParser';

export const useFirestoreCollection = <T extends { id: string } = any>(
	path: string,
	options?: firebase.firestore.GetOptions
) => {
	const { lang } = useLang();

	const requestPromise = useMemo(async () => {
		const querySnapshot = await firebase.firestore().collection(path).get(options);
		const data: T[] = [];
		querySnapshot.forEach((doc) => data.push({ ...doc.data(), id: doc.id } as T));
		return data;
	}, [options, path]);

	const { setRequest, ...state } = useFetcher(requestPromise, []);

	return { ...useLanguageResponseParser<T>(state, lang), raw: state.response };
};
