import { LanguageScreen } from 'modules/LanguageScreen/LanguageScreen';
import React, { createContext, FunctionComponent, useContext, useState } from 'react';

import en from '../../constants/lang/en.json';
import ro from '../../constants/lang/ro.json';

export enum AvailableLanguages {
	en = 'en',
	ro = 'ro',
}

interface LanguageContextInterface {
	lang: AvailableLanguages;
	setLang: (lang: AvailableLanguages) => void;
	internationalization: Record<string, string>;
	getMessage: (id: string) => string;
}

interface FormattedMessageProps {
	id: string;
}

const LanguageContext = createContext<LanguageContextInterface>({
	lang: AvailableLanguages.en,
	setLang: () => ({}),
	internationalization: {} as Record<string, string>,
	getMessage: () => '',
});

export const LanguageProvider: FunctionComponent = ({ children }) => {
	const [lang, setLang] = useState<AvailableLanguages | null>(
		localStorage.getItem('lang') as AvailableLanguages | null
	);

	const handleLang = (lang: AvailableLanguages): void => {
		localStorage.setItem('lang', lang);
		setLang(lang);
	};

	if (!lang) {
		return <LanguageScreen setLang={handleLang} />;
	}

	const getMessage = (id: string): string => internationalization[id] || id;
	const internationalization = { en, ro }[lang] as Record<string, string>;
	return (
		<LanguageContext.Provider value={{ lang, setLang: handleLang, internationalization, getMessage }}>
			{children}
		</LanguageContext.Provider>
	);
};

export const useLang = (): LanguageContextInterface => useContext(LanguageContext);

/** Component - renders a language text  */
export const FormattedMessage: FunctionComponent<FormattedMessageProps> = ({ id }) => {
	const { getMessage } = useLang();
	return <>{getMessage(id)}</>;
};
