import { LoginPage } from 'modules/LoginPage/LoginPage';
import { ProfilePage } from 'modules/ProfilePage/ProfilePage';
import { AboutUsPage } from 'modules/AboutUsPage/AboutUsPage';
import { ContactPage } from 'modules/ContactPage/ContactPage';
import { LandingPage } from 'modules/LandingPage/LandingPage';
import { ProjectPage } from 'modules/Projects/Project/ProjectPage';
import { ProjectsPage } from 'modules/Projects/ProjectsPage';
import { DashboardPage } from 'modules/DashboardPage/Dashboard';
import { CustomRouteProps } from 'components';

const LANDING_PAGE_PATH = '/';
const PROJECTS_PATH = '/projects';
const CONTACT_PATH = '/contact';
const ABOUT_US_PATH = '/about';
const PROFILE_PATH = '/profile/:id';
const LOGIN_PATH = '/login';
const DASHBOARD_PATH = ['/dashboard', '/dashboard/:id'];
export const PROJECT_PATH = ['/projects/:id/:name', '/projects/:id/:name/:section'];

export const APP_ROUTES: CustomRouteProps[] = [
	{ path: LANDING_PAGE_PATH, component: LandingPage, exact: true },
	{ path: PROJECTS_PATH, component: ProjectsPage, exact: true },
	{ path: CONTACT_PATH, component: ContactPage, exact: true },
	{ path: ABOUT_US_PATH, component: AboutUsPage, exact: true },
	{ path: PROJECT_PATH, component: ProjectPage, exact: true },
	{ path: PROFILE_PATH, component: ProfilePage, exact: true },
	{ path: LOGIN_PATH, component: LoginPage, exact: true },
	{ path: DASHBOARD_PATH, component: DashboardPage, exact: true, private: true },
];
