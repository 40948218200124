import React, { FunctionComponent } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './MultipleCarousel.css';

interface MultipleCrouselProps {
	images: string[];
	onImageClick?: (index: number) => void;
}

export const MultipleCrousel: FunctionComponent<MultipleCrouselProps> = ({ images, onImageClick }) => {
	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 5,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 2,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1.5,
		},
	};
	return (
		<Carousel
			draggable={false}
			responsive={responsive}
			infinite={true}
			keyBoardControl={true}
			centerMode={false}
			containerClass="h-auto multiple-carousel-container"
			removeArrowOnDeviceType={[]}
			focusOnSelect={true}
			arrows={true}
			swipeable={true}
		>
			{images.map((item, index) => (
				<div className="w-full px-4 md:px-10 cursor-pointer" key={index} onClick={() => onImageClick?.(index)}>
					<div
						className="w-full bg-cover bg-center rounded-2xl"
						style={{ backgroundImage: `url("${item}")`, paddingBottom: '77%' }}
					/>
				</div>
			))}
		</Carousel>
	);
};
