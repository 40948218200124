import React, { useEffect } from 'react';
import { LanguageProvider } from 'state/LanguageContext/LanguageContext';

import { RouterWrapper } from './components';
import './App.css';
import { APP_ROUTES } from 'constants/routes';
import { StateProvider } from 'modules/StateProvider/StateProvider';
import { ProjectCarouselProvider } from 'modules/Projects/ProjectCarousel/ProjectCarouselContext';
import { useLocation } from 'react-router-dom';

function App(): JSX.Element {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return (
		<LanguageProvider>
			<StateProvider>
				<ProjectCarouselProvider>
					<RouterWrapper routes={APP_ROUTES} />
				</ProjectCarouselProvider>
			</StateProvider>
		</LanguageProvider>
	);
}

export default App;
