import classNames from 'classnames';
import { Button, Input } from 'components';
import { Project } from 'modules/Projects/types';
import React, { Fragment, FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IProjectForm, useConfig } from './config';
import firebase from 'firebase';
import { DefaultEditor } from 'react-simple-wysiwyg';
import Select from 'react-select';

interface FormProjectPropTypes {
	className?: string;
	defaultProjectValues?: Project;
}

export const FormProject: FunctionComponent<FormProjectPropTypes> = ({ className, defaultProjectValues }) => {
	const config = useConfig();

	const { handleSubmit, control, errors } = useForm<Project>({
		defaultValues: defaultProjectValues,
		mode: 'onBlur',
		reValidateMode: 'onChange',
	});

	const onSubmit = (data: IProjectForm) => {
		if (defaultProjectValues?.id) {
			firebase
				.firestore()
				.collection('projects')
				.doc(defaultProjectValues.id)
				.set({ ...defaultProjectValues, ...data })
				.then(() => {
					console.log('written');
				})
				.catch((err) => {
					console.log('error', err);
				});
			return;
		}
		firebase
			.firestore()
			.collection('projects')
			.add(data)
			.then(() => {
				console.log('written new data');
			})
			.catch((err) => {
				console.log('error addind new data', err);
			});
	};

	return (
		<div className={classNames('grid grid-cols-12', className)}>
			<form onSubmit={handleSubmit(onSubmit)} autoComplete="on" className="col-start-2 col-end-12">
				{config.map((field) => (
					<Fragment key={field.name}>
						<Controller
							name={field.name}
							control={control}
							defaultValue={field.default}
							rules={{
								required: { value: !!field.required, message: 'This field is required' },
							}}
							render={({ onChange, onBlur, value }) => {
								switch (field.type) {
									case 'checkbox':
										return (
											<p className="font-cera flex items-center space-x-3 mb-6">
												<label>
													<input
														className="h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
														type="checkbox"
														checked={value}
														onChange={onChange}
														onBlur={onBlur}
													/>
													<span className="text-xl ml-4 font-cera">{field.label}</span>
												</label>
											</p>
										);
									case 'dropdown':
										console.log(field.name, value);
										return (
											<div className="mb-10 font-cera">
												<div className="text-xl mb-2 font-cera">{field.label}</div>
												<Select
													options={field.options}
													onChange={({ value }) => onChange(value)}
													value={value}
													onBlur={onBlur}
												/>
											</div>
										);
									case 'html':
										return (
											<div className="mb-10 font-cera">
												<span className="text-xl mb-6 font-cera">{field.label}</span>
												<DefaultEditor value={value} onChange={onChange} onBlur={onBlur} />
											</div>
										);
									default:
										return <Input label={field.label} value={value} onChange={onChange} onBlur={onBlur} />;
								}
							}}
						/>
						{field.name
							.split('.')
							.reduce((errorMessage, current) => errorMessage?.[current as keyof Project] as any, errors) && (
							<div className="text-red-500 -mt-6 mb-8">This field is required</div>
						)}
					</Fragment>
				))}
				<div className="text-center">
					<Button theme="dark" label="submit" onClick={handleSubmit(onSubmit)} />
				</div>
			</form>
		</div>
	);
};
