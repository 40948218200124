import { useLang } from 'state/LanguageContext/LanguageContext';
import { IFormInputs } from './form';

type IUseConfigReturnType = {
	name: keyof IFormInputs;
	label: string;
	placeholder: string;
	type?: string;
	textarea?: boolean;
	numberOfRows?: number | undefined;
}[];

export const useConfig = (): IUseConfigReturnType => {
	const { getMessage } = useLang();

	return [
		{
			name: 'email',
			textarea: false,
			type: 'text',
			label: getMessage('contact_email_label'),
			placeholder: getMessage('contact_email_placeholder'),
		},
		{
			name: 'password',
			textarea: false,
			type: 'password',
			label: getMessage('password'),
			placeholder: getMessage('password_label'),
		},
	];
};
