import { Page } from 'components';
import React from 'react';
import { Main } from './Main';
import { SideContainer } from './SideContainer';

export const DashboardPage = () => {
	return (
		<Page className="bg-gray-50 p-4 grid grid-cols-12">
			<aside className="col-start-1 col-end-4">
				<SideContainer />
			</aside>
			<main className="col-start-4 col-end-13">
				<Main />
			</main>
		</Page>
	);
};
