import { FunctionComponent } from 'react';
import { Menu } from '@headlessui/react';
import { AvailableLanguages, FormattedMessage, useLang } from 'state/LanguageContext/LanguageContext';

export const LanguagePicker: FunctionComponent = () => {
	const { lang, setLang } = useLang();
	return (
		<Menu>
			<Menu.Button className="outline-none focus:outline-none whitespace-nowrap">
				<FormattedMessage id="language"></FormattedMessage>: <span className="text-light">{lang}</span>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					className="w-4 inline-block ml-2"
				>
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
				</svg>
			</Menu.Button>
			<Menu.Items className="outline-none focus:outline-none">
				<Menu.Item
					className="outline-none focus:outline-none cursor-pointer"
					onClick={() => setLang(AvailableLanguages.ro)}
					as="div"
				>
					<span>romana</span>
				</Menu.Item>
				<Menu.Item
					className="outline-none focus:outline-none cursor-pointer"
					onClick={() => setLang(AvailableLanguages.en)}
					as="div"
				>
					<span>english</span>
				</Menu.Item>
			</Menu.Items>
		</Menu>
	);
};
