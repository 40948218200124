import { MultipleCrousel } from 'components';
import React, { FunctionComponent } from 'react';
import { useProjectCarousel } from '../ProjectCarousel/ProjectCarouselContext';
import { useProject } from './ProjectContext';

export const ProjectGallery: FunctionComponent = () => {
	const { project } = useProject();

	const { open } = useProjectCarousel();

	return (
		<>
			{project.gallery?.map((galleryItem, index) => {
				return (
					<div className="w-full flex flex-col pb-12 md:pb-10 lg:pb-16" key={index}>
						<h1 className="font-bebas px-7 md:px-10 lg:px-20 text-3xl md:text-6xl mb-4 md:mb-8">{galleryItem.title}</h1>
						{galleryItem.images && (
							<MultipleCrousel
								images={galleryItem.images}
								onImageClick={(imageIndex) => open(project, 'gallery', index, imageIndex)}
							/>
						)}
					</div>
				);
			})}
		</>
	);
};
