import { Input, Button } from 'components';
import { useForm, Controller } from 'react-hook-form';
import { useLang } from 'state/LanguageContext/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useConfig } from './config';
import { useFetcher } from 'helpers/useFetcher';
import { useCallback, useEffect, useState } from 'react';

export interface IFormInputs {
	fullName: string;
	email: string;
	phoneNumber: string;
	message?: string;
}
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const API_URL = `https://mail-service-td53xpee3q-ew.a.run.app/mail/`;
export const Form = () => {
	const { getMessage } = useLang();
	const fields = useConfig();
	const [finished, setFinished] = useState(false);
	const formSchema = yup.object().shape({
		fullName: yup.string().max(30, getMessage('contact_field_toLong')).required(getMessage('contact_field_required')),
		email: yup
			.string()
			.email()
			.max(255, getMessage('contact_field_toLong'))
			.required(getMessage('contact_field_required')),
		phoneNumber: yup
			.string()
			.matches(phoneRegExp, getMessage('contact_field_phoneNumber_invalid'))
			.required(getMessage('contact_field_required')),
		message: yup.string().max(2000, getMessage('contact_field_toLong')).notRequired(),
	});

	const { handleSubmit, errors, control, reset } = useForm<IFormInputs>({
		resolver: yupResolver(formSchema),
		defaultValues: {
			fullName: '',
			email: '',
			phoneNumber: '',
			message: '',
		},
	});
	const sendMailRequest = useCallback(<T,>(data?: T) => {
		if (!data) {
			return;
		}
		const payload = {
			to: 'office@qubdesign.ro',
			subject: 'New message from qubdesign.ro',
			header: 'You recieved a message from someone using the mail form on qubdesign.ro',
			parameters: Object.entries(data).map((entry) => ({
				name: entry[0],
				value: entry[1],
			})),
		};
		return fetch(API_URL, {
			method: 'POST',
			body: JSON.stringify(payload),
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': `${window.location.origin}`,
			},
		}).then(() => ({}));
	}, []);
	const { setRequest, loading, error, response } = useFetcher(sendMailRequest(), undefined);

	useEffect(() => {
		if (response) {
			reset();
			setFinished(true);
		}
	}, [reset, response, setFinished]);

	const onSubmit = (data: IFormInputs) => {
		setRequest(sendMailRequest(data));
	};

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
				{error && (
					<div className="bg-red-600 text-white rounded-lg text-xl font-thin font-cera mb-4 px-6">
						{getMessage('error')}
					</div>
				)}
				{finished && (
					<div className="bg-green-600 text-white rounded-lg text-xl font-thin font-cera mb-4 px-6">
						{getMessage('succes')}
					</div>
				)}
				{fields.map((field) => {
					return (
						<Controller
							key={field.name}
							name={field.name}
							control={control}
							render={({ onChange, onBlur, value }) => (
								<Input
									label={field.label}
									placeholder={field.placeholder}
									value={value}
									multiline={field.textarea}
									numberOfRows={field?.numberOfRows}
									errorMessage={errors[field.name]?.message}
									onChange={onChange}
									onBlur={onBlur}
								/>
							)}
						/>
					);
				})}
				<div className="text-center mt-14">
					<Button
						type="submit"
						label={getMessage('contact_submit_button')}
						disabled={loading}
						theme="dark"
						className="bg-black border-2 border-black hover:opacity-70"
					/>
				</div>
			</form>
		</div>
	);
};
