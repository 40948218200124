import 'firebase/firestore';

import firebase from 'firebase';

export const loadFirebase = () => {
	firebase.initializeApp({
		apiKey: 'AIzaSyDZrnJLYLeEi-YpoaZe6eL220bhVFV-7FY',
		authDomain: 'qub-design.firebaseapp.com',
		projectId: 'qub-design',
		storageBucket: 'qub-design.appspot.com',
		messagingSenderId: '1031953006480',
		appId: '1:1031953006480:web:c87febd6e894fe9f0bfd15',
		measurementId: 'G-NQ3NK4KE4L',
	});
	firebase.analytics();
	firebase.firestore();
};
