import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'state/LanguageContext/LanguageContext';

import { ReactComponent as Arrow } from '../../assets/arrow.svg';

interface BackButtonProps {
	/** The color of the button */
	theme?: 'light' | 'dark';
	/** Path to be followed when the button is clicked */
	to?: string;
	/** Action to be performed when the button is clicked */
	onClick?: () => void;
	/** Class Name for the button container */
	className?: string;
	/** Class Name for the arrow icon */
	arrowClassName?: string;
	/** Overrides the back button label. Should be an id from the internationalization object */
	labelId?: string;
	/** When `true` this option disabled the back arrow */
	hideArrow?: boolean;
}

export const BackButton: FunctionComponent<BackButtonProps> = ({
	theme = 'light',
	onClick,
	arrowClassName,
	className,
	labelId = 'common__back-button__full',
	hideArrow,
	to = '/',
}) => {
	const { goBack } = useHistory();
	const handleGoBack = () => {
		goBack();
	};
	return (
		<Link
			to={to}
			onClick={!to ? onClick || handleGoBack : undefined}
			className={classNames('font-cera', theme === 'dark' ? 'text-black' : 'text-white')}
		>
			<div className={classNames(className)}>
				{!hideArrow && <Arrow className={classNames('inline-block mr-3.5', arrowClassName)} />}
				<div className="inline-block" style={theme === 'dark' ? { color: 'black' } : { color: 'white' }}>
					<FormattedMessage id={labelId} />
				</div>
			</div>
		</Link>
	);
};
