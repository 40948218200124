import { getProjectLink } from 'helpers/getProjectLink';
import { useGlobalState } from 'modules/StateProvider/StateProvider';
import React, { FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useProject } from './ProjectContext';

export const SimilarProjects: FunctionComponent = () => {
	const { projects } = useGlobalState();
	const { project } = useProject();
	const similarProjectsList = useMemo(() => project.similar || [], [project]);

	const similarProjects = useMemo(() => projects.filter((project) => similarProjectsList.includes(project.id)), [
		projects,
		similarProjectsList,
	]);
	return (
		<div className="w-full grid grid-flow-row grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-rows-1 gap-8 px-8 md:px-10 lg:px-20 pb-6 md:pb-10 lg:pb-16">
			{similarProjects.map((similarProject) => (
				<Link key={similarProject.id} to={getProjectLink(similarProject)}>
					<div className="mb-6">
						<div
							className="bg-cover bg-center w-full rounded-2xl transition timing-300 hover:opacity-80"
							style={{ backgroundImage: `url("${similarProject.photoUrl}")`, paddingBottom: '66%' }}
						/>
						<div className="text-3xl md:text-4xl font-bebas mt-4 md:mt-6 ml-3 md:ml-0">{similarProject.title}</div>
					</div>
				</Link>
			))}
		</div>
	);
};
