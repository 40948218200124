import React, { FunctionComponent, useState } from 'react';

import { Logo } from 'components/Logo/Logo';
import { NavLink } from 'react-router-dom';
import { MobileMenu } from './MobileMenu';
import classNames from 'classnames';
import { useLang } from 'state/LanguageContext/LanguageContext';

export const NavBar: FunctionComponent = () => {
	const [state, setState] = useState(false);
	const [transition, setTransition] = useState(false);
	const { getMessage } = useLang();

	const MENU_ITEMS = [
		{ title: 'menu_home', link: '/' },
		{ title: 'menu_projects', link: '/projects' },
		{ title: 'menu_about', link: '/about' },
		{ title: 'menu_contact', link: '/contact' },
	];

	const toggleWindow = () => {
		if (state) {
			setTransition(true);
			setTimeout(() => {
				setTransition(false);
				setState(false);
			}, 300);
			return;
		}
		setState(true);
	};

	return (
		<>
			{state && <MobileMenu fadeOut={transition} menuItems={MENU_ITEMS} />}
			<div className="absolute justify-between w-full flex-row flex z-30 py-6 px-6 sm:py-12 sm:px-12 lg:py-16 lg:px-16">
				<Logo className="fade-in" />
				<div className=" my-auto fade-in hidden sm:flex">
					{MENU_ITEMS.map((item, index) => (
						<NavLink
							activeClassName="font-medium"
							to={item.link}
							key={index}
							className="font-cera text-white px-4 font-light text-xl"
						>
							{getMessage(item.title)}
						</NavLink>
					))}
				</div>
				<div
					className="my-auto fade-in flex sm:hidden cursor-pointer relative w-12"
					style={{ height: 30.5 }}
					onClick={!transition ? toggleWindow : undefined}
				>
					<div
						className={classNames(
							'w-10 h-0.5 left-2 rounded-md absolute top-0 bg-white duration-300 origin-left',
							state && !transition && 'transform rotate-45'
						)}
					/>
					<div
						className={classNames(
							'w-10 h-0.5 left-0 rounded-md absolute top-0 bottom-0 m-auto bg-white duration-300',
							state && !transition && 'opacity-0'
						)}
					/>
					<div
						className={classNames(
							'w-10 h-0.5 left-2 rounded-md absolute bottom-0 m-auto bg-white duration-300 origin-left',
							state && !transition && 'transform -rotate-45'
						)}
					/>
				</div>
			</div>
		</>
	);
};
