import classNames from 'classnames';
import { Button, Logo } from 'components';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { useLang } from 'state/LanguageContext/LanguageContext';

import classes from './NotFound.module.css';

export const NotFound: FunctionComponent = () => {
	const { push } = useHistory();
	const { getMessage } = useLang();
	return (
		<main
			className={classNames(
				classes.background,
				'min-h-screen bg-cover bg-center flex flex-col-reverse lg:flex-row justify-around items-center'
			)}
		>
			<div className="flex flex-col h-full p-4 fade-in">
				<div className="text-white text-56 text-5xl font-bebas mb-20 max-w-xs mt-auto text-center md:text-left ">
					{getMessage('error')}
					<br />
					{getMessage('not-found-message')}
				</div>
				<div className="flex mb-auto flex-col md:flex-row">
					<Button
						className="self-center md:self-start mb-8 bd:mb-0"
						label={getMessage('return-to-homepage')}
						onClick={() => push('/')}
					/>
				</div>
			</div>
			<div className="">
				<Logo className="w-40 h-40 md:w-56 md:h-56" />
			</div>
		</main>
	);
};
