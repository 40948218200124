import classNames from 'classnames';
import { Button, Logo } from 'components';
import React, { FunctionComponent } from 'react';
import { AvailableLanguages } from 'state/LanguageContext/LanguageContext';

import classes from './LanguageScreen.module.css';

export interface LanguageScreenProps {
	setLang: (lang: AvailableLanguages) => void;
}

export const LanguageScreen: FunctionComponent<LanguageScreenProps> = ({ setLang }) => (
	<main
		className={classNames(
			classes.background,
			'min-h-screen bg-cover bg-center flex flex-col-reverse lg:flex-row justify-around items-center'
		)}
	>
		<div className="flex flex-col h-full p-4 fade-in">
			<div className="text-white text-56 text-5xl font-bebas mb-20 max-w-xs mt-auto text-center md:text-left ">
				please choose your prefered language
			</div>
			<div className="flex mb-auto flex-col md:flex-row">
				<Button
					className="self-center md:self-start mb-8 bd:mb-0"
					label="English"
					onClick={() => setLang(AvailableLanguages.en)}
				/>
				<div className="w-0 ml-12 mr-12 h-20 border-white border transform rotate-30 hidden md:block"></div>
				<Button className="self-center md:self-end" onClick={() => setLang(AvailableLanguages.ro)} label="Română" />
			</div>
		</div>
		<div className="">
			<Logo className="w-40 h-40 md:w-56 md:h-56" />
		</div>
	</main>
);
