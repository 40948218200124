import { Page } from 'components';
import classNames from 'classnames';

import classes from './ProfilePage.module.css';
import React, { useMemo } from 'react';
import { GoBackHeader } from 'components/NavBar/GoBackHeader';
import { useParams } from 'react-router-dom';
import { useGlobalState } from 'modules/StateProvider/StateProvider';
import { NotFound } from 'modules/NotFound/NotFound';

export interface IProfile {
	id: string;
	name: string;
	description: string;
	image: string;
}

export const ProfilePage = () => {
	const { profiles } = useGlobalState();
	const { id } = useParams<{ id: string }>();

	const selectedProfile = useMemo(() => profiles.find((profile) => profile.id === id), [profiles, id]);

	if (profiles && !selectedProfile) {
		return <NotFound />;
	}

	return (
		<Page className={classNames(classes.background, 'bg-cover min-h-screen flex items-stretch')}>
			<GoBackHeader />
			<div className="w-screen mb-0 mt-40 sm:mt-80 bg-white rounded-3xl rounded-b-none">
				<div className="grid grid-cols-12 py-16 sm:py-40 px-5">
					<div
						className="w-full pb-full h-0 rounded-full bg-cover bg-center col-start-1 sm:col-start-1 lg:col-start-3 col-end-13 sm:col-end-6 lg:col-end-6"
						style={{ backgroundImage: `url("${selectedProfile?.image}")` }}
					></div>
					<div className="col-start-1 sm:col-start-7 lg:col-start-7 col-end-13 sm:col-end-13 lg:col-end-10 sm:my-auto pt-8 sm:pt-0 text-center sm:text-left">
						<p className="font-bebas font-bold text-black text-6xl">{selectedProfile?.name.toUpperCase()}</p>
						<p className="font-cera font-normal">{selectedProfile?.description}</p>
					</div>
				</div>
			</div>
		</Page>
	);
};
