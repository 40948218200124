import { Input, Button } from 'components';
import { useForm, Controller } from 'react-hook-form';
import { useLang } from 'state/LanguageContext/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useConfig } from './config';
import firebase from 'firebase';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { useState } from 'react';

export interface IFormInputs {
	password: string;
	email: string;
}

export const Form = () => {
	const { getMessage } = useLang();
	const fields = useConfig();
	const [error, setError] = useState(null as null | Error);
	const [loading, setLoading] = useState(false);
	const { push } = useHistory();
	console.log(error);
	const formSchema = yup.object().shape({
		password: yup.string().required(getMessage('contact_field_required')),
		email: yup
			.string()
			.email()
			.max(255, getMessage('contact_field_toLong'))
			.required(getMessage('contact_field_required')),
	});

	const { handleSubmit, errors, control } = useForm<IFormInputs>({
		resolver: yupResolver(formSchema),
		defaultValues: {
			password: '',
			email: '',
		},
	});

	const onSubmit = (data: IFormInputs) => {
		setError(null);
		setLoading(true);
		firebase
			.auth()
			.signInWithEmailAndPassword(data.email, data.password)
			.then(() => {
				push('/dashboard');
			})
			.catch((error) => {
				setError(error);
				const errorCode = error.code;
				const errorMessage = error.message;
				console.log('errorCode', errorCode);
				console.log('errorMessage', errorMessage);
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className="grid grid-cols-12 py-16">
			<form onSubmit={handleSubmit(onSubmit)} autoComplete="on" className="col-start-2 col-end-12">
				{fields.map((field) => {
					return (
						<Controller
							key={field.name}
							name={field.name}
							control={control}
							render={({ onChange, onBlur, value }) => (
								<Input
									label={field.label}
									placeholder={field.placeholder}
									value={value}
									type={field.type}
									multiline={field.textarea}
									numberOfRows={field?.numberOfRows}
									errorMessage={errors[field.name]?.message}
									onChange={onChange}
									onBlur={onBlur}
								/>
							)}
						/>
					);
				})}
				<div className="text-center mt-14">
					<Button
						type="submit"
						label="log in"
						disabled={loading}
						theme="dark"
						className="bg-black border-2 border-black hover:opacity-70 focus:opacity-70"
					/>
				</div>
			</form>
		</div>
	);
};
