import { Page, Carousel, Button, RouterWrapper } from 'components';
import { GoBackHeader } from 'components/NavBar/GoBackHeader';
import { getProjectLink } from 'helpers/getProjectLink';
import { Footer } from 'modules/Footer/Footer';
import { NotFound } from 'modules/NotFound/NotFound';
import { useGlobalState } from 'modules/StateProvider/StateProvider';
import React, { FunctionComponent, useMemo } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useLang } from 'state/LanguageContext/LanguageContext';
import { useProjectCarousel } from '../ProjectCarousel/ProjectCarouselContext';
import { Project } from '../types';
import { PROJECTS_ROUTES } from './constants';
import { ProjectContext } from './ProjectContext';
import './ProjectPage.css';

export const ProjectPage: FunctionComponent<Project> = () => {
	const { projects, profiles } = useGlobalState();
	const { id, section } = useParams<{ id: string; section?: string }>();
	const { push } = useHistory();
	const { getMessage } = useLang();
	const { open } = useProjectCarousel();

	const { selectedProject, previousProject, nextProject } = useMemo(() => {
		const selectedProjectIndex = projects.findIndex((project) => project.id === id);
		const selectedProject: Project | undefined = projects[selectedProjectIndex];
		const previousProject = projects[selectedProjectIndex - 1];
		const nextProject = projects[selectedProjectIndex + 1];
		return { selectedProject, previousProject, nextProject };
	}, [projects, id]);

	const profile = useMemo(() => {
		return selectedProject?.mainDesign
			? profiles.find((profile) => profile.id === selectedProject?.mainDesign)
			: undefined;
	}, [selectedProject, profiles]);

	if (!selectedProject) {
		return <NotFound />;
	}
	const projectLink = getProjectLink(selectedProject);

	return (
		<Page className="w-full bg-black relative">
			<ProjectContext.Provider value={{ project: selectedProject, previousProject, nextProject }}>
				<div className="w-full fade-in">
					<div className="h-1/2-screen sm:h-4/5-screen">
						<Carousel
							key={id}
							imgs={[selectedProject.photoUrl, ...(selectedProject.featuredPhotos || [])]}
							transitionDuration={500}
							className="w-full h-full"
							buttonsClassName="hidden md:block"
							onImageClick={(index: number) => open(selectedProject, 'featured', 0, index - 1)}
							photoClassName="cursor-pointer"
						/>
						<GoBackHeader to="/projects" />
					</div>
					<div className="relative bg-white w-full rounded-3xl -top-8">
						<div className="absolute w-full flex -top-20 px-3 md:px-6 lg:px-14 overflow-auto pb-4">
							<NavLink
								to={`${projectLink}`}
								className="mx-4 min-w-250 w-1/3"
								activeClassName="project-link-active"
								exact
							>
								<Button label={getMessage('project__description-title')} className="w-full" />
							</NavLink>
							<NavLink
								to={`${projectLink}/gallery`}
								className="mx-4 min-w-250 w-1/3"
								activeClassName="project-link-active"
								exact
							>
								<Button label={selectedProject.galleryLabel} className="w-full" />
							</NavLink>
							<NavLink
								to={`${projectLink}/similar`}
								className="mx-4 min-w-250 w-1/3"
								activeClassName="project-link-active"
								exact
							>
								<Button label={getMessage('project__similar-title')} className="w-full" />
							</NavLink>
						</div>
						<div className="w-full flex flex-col sm:flex-row px-8 md:px-10 lg:px-20 py-10 md:py-10 lg:py-16">
							<div className="flex-grow">
								<h1 className="font-bebas text-4xl md:text-6xl">{selectedProject.title}</h1>
								<h3 className="font-cera font-regular text-sm md:text-xl">{selectedProject.address}</h3>
							</div>
							{profile && !section && (
								<div className="shrink-0 mt-6 sm:mt-0 cursor-pointer" onClick={() => push(`/profile/${profile.id}`)}>
									<h2 className="font-bebas text-3xl md:text-4xl">{getMessage('project__design_principal')} </h2>
									<h3 className="font-cera font-regular text-sm md:text-xl">{profile.name}</h3>
								</div>
							)}
						</div>
						{selectedProject.airBnbLink && (
							<div className="w-full flex flex-col sm:flex-row px-6 md:px-10 lg:px-20 pb-6 md:pb-10 lg:pb-16 font-cera">
								<a href={selectedProject.airBnbLink} target="_new">
									<Button className="bg-black border-none text-white hover:text-white font-light">
										<i className="fab fa-airbnb mr-2"></i>
										<FormattedMessage id="rent-now" />
									</Button>
								</a>
							</div>
						)}
						<div className="w-full flex flex-col sm:flex-row px-6 md:px-10 lg:px-20 pb-6 md:pb-10 lg:pb-16 font-cera">
							{selectedProject.description}
						</div>
						<div className="w-full">
							<RouterWrapper routes={PROJECTS_ROUTES} />
						</div>
					</div>
				</div>
				<Footer className="mt-auto" />
			</ProjectContext.Provider>
		</Page>
	);
};
