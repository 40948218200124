import { throttle } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

type MediaQueryBreakpoints = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

type useMediaQueryProps<T> = {
	queries: { [K in MediaQueryBreakpoints]?: T };
};

const MediaQueriesMap = {
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	'2xl': 1536,
};

export const useMediaQuery = <T>({ queries }: useMediaQueryProps<T>): T => {
	const [match, setMatch] = useState(Object.values(queries)[0] as T);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedWindowResize = useCallback(
		throttle(() => {
			const { innerWidth } = window;
			const queriesArray = Object.entries(queries) as [MediaQueryBreakpoints, T][];
			const sortedArray = queriesArray.sort((a, b) => MediaQueriesMap[a[0]] - MediaQueriesMap[b[0]]);
			const breakpoint =
				sortedArray.find(([breakpoint]) => innerWidth < MediaQueriesMap[breakpoint]) ||
				sortedArray[sortedArray.length - 1];
			setMatch(breakpoint[1]);
		}, 500),
		[]
	);

	useEffect(() => {
		const handleWindowResize = () => {
			debouncedWindowResize();
		};
		handleWindowResize();
		window.addEventListener('resize', handleWindowResize);
		return () => window.removeEventListener('resize', handleWindowResize);
	}, [debouncedWindowResize]);

	return match;
};
