import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { ButtonPropTypes } from './Button.props';

const Loading = () => {
	return (
		<div className="fa-1x text-white">
			<i className="fas fa-circle-notch fa-spin"></i>
		</div>
	);
};

export const Button: FunctionComponent<ButtonPropTypes> = ({
	onClick,
	label,
	className,
	type,
	disabled,
	children,
	theme = 'light',
	labelClassName,
}) => (
	<button
		onClick={onClick}
		className={classNames(
			theme === 'dark' ? 'bg-black border-2 border-black hover:opacity-70' : 'bg-transparent border-2 border-white',
			'rounded-3xl py-2 min-w-250 px-4 max-w-full hover:text-black transition duration-200 text-sm cursor-pointer shadow-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 disable:bg-gray-500',
			className
		)}
		type={type}
		disabled={disabled}
	>
		{disabled ? (
			<Loading />
		) : (
			children || (
				<span className={classNames('font-cera font-normal text-md text-white ', labelClassName)}>{label}</span>
			)
		)}
	</button>
);
