import { Carousel } from 'components/Carousel/Carousel';
import React, { createContext, FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { Project } from '../types';
import './ProjectCarouselContext.css';
import { Transition } from '@headlessui/react';

interface IProjectCarouselContext {
	open: (project: Project, type?: 'featured' | 'gallery', galleryIndex?: number, index?: number) => void;
}
export const ProjectCarouselContext = createContext<IProjectCarouselContext>({
	open: () => ({}),
});
export const useProjectCarousel = () => useContext(ProjectCarouselContext);

export const ProjectCarouselProvider: FunctionComponent = ({ children }) => {
	const [state, setState] = useState<{
		project?: Project;
		open: boolean;
		gelleryIndex?: number;
		index?: number;
		type?: 'featured' | 'gallery';
	}>({ open: false });

	const open = (project: Project, type: 'featured' | 'gallery' = 'featured', gelleryIndex?: number, index?: number) => {
		setState({ project, type, index, open: true, gelleryIndex });
	};
	const close = () => setState((oldState) => ({ ...oldState, open: false }));

	useEffect(() => {
		const oldState = document.body.style.overflow;
		if (state.open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => {
			document.body.style.overflow = oldState;
		};
	}, [state.open]);
	const featuredArray = [state?.project?.photoUrl, ...(state?.project?.featuredPhotos || [])];
	const imgs =
		state.type === 'featured'
			? featuredArray
			: state.project?.gallery?.[state.gelleryIndex || 0]?.images || featuredArray;

	const carouselElement = useMemo(() => {
		return (
			<Carousel
				key={state.index}
				imgs={imgs}
				defaultIndex={state.index}
				transitionDuration={500}
				className="w-full h-full"
			/>
		);
	}, [imgs, state.index]);
	return (
		<ProjectCarouselContext.Provider value={{ open }}>
			{children}
			<Transition
				show={state.open}
				enter="transition-opacity duration-500"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-500"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				className="fixed top-0 right-0 bottom-0 left-0 transition-opacity opacity-1"
			>
				<div className="w-full h-full fade-in">{carouselElement}</div>
				<div className="flex fixed top-0 px-7 sm:px-14 py-8 sm:py-16 fade-in">
					<div
						className="dismiss-icon w-7 h-7 bg-contain bg-center bg-no-repeat mt-0.5 cursor-pointer"
						onClick={close}
					/>
					<h1 className="font-bebas text-4xl text-white pl-12">{state.project?.title}</h1>
				</div>
			</Transition>
		</ProjectCarouselContext.Provider>
	);
};
