import { useGlobalState } from 'modules/StateProvider/StateProvider';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'state/LanguageContext/LanguageContext';
import { LanguagePicker } from './LanguagePicker';
import classNames from 'classnames';

export const Footer: FunctionComponent<{ className?: string }> = ({ className }) => {
	const { footer } = useGlobalState();
	return (
		<div
			className={classNames(
				'bg-black py-20 px-16 w-screen text-white flex-1 grid grid-cols-1 md:grid-cols-3 gap-12 font-cera',
				className
			)}
		>
			<div className="text-center md:text-left">
				<p className="font-medium">
					<FormattedMessage id="footer__address" />
				</p>
				<div className="font-light">
					<p>{footer?.addressLine1}</p>
					<p>{footer?.addressLine2}</p>
				</div>
			</div>
			<div className="text-center">
				<p className="font-medium">
					<FormattedMessage id="footer__contact" />
				</p>
				<div>
					{footer.email && (
						<p>
							<span className="font-regular">
								<FormattedMessage id="footer__email" />
							</span>
							<a href={`mailto:${footer.email}`} className="font-light">
								: {footer.email}
							</a>
						</p>
					)}
					{footer.phone && (
						<p>
							<span className="font-regular">
								<FormattedMessage id="footer__phone" />
							</span>
							<a href={`tel:${footer.phone}`} className="font-light">
								: {footer.phone}
							</a>
						</p>
					)}
				</div>
				<LanguagePicker />
			</div>
			<div className="text-center md:text-right">
				<p className="font-medium">
					<FormattedMessage id="footer__social" />
				</p>

				<div>
					{Object.entries(footer?.social || {}).map((entry, index) => {
						return (
							<a
								href={entry[1]}
								target="_new"
								key={index}
								className="w-10 h-10 inline-flex ml-2 mt-2 hover:bg-gray-800 bg-opacity-70 border-gray-100 border-solid border items-center justify-center rounded-full"
							>
								<i className={`fab fa-${entry[0]}`}></i>
							</a>
						);
					})}
				</div>
			</div>
		</div>
	);
};
