import classNames from 'classnames';
import React, { PropsWithChildren, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useLang } from 'state/LanguageContext/LanguageContext';
import mobileBackground from '../../assets/mobile-menu-background.jpg';

interface MobileMenuProps {
	fadeOut?: boolean;
	menuItems: { title: string; link: string }[];
}

export const MobileMenu = ({ fadeOut, menuItems }: PropsWithChildren<MobileMenuProps>) => {
	const { getMessage } = useLang();
	useEffect(() => {
		const oldState = document.body.style.overflow;
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = oldState;
		};
	});
	return (
		<div
			className={classNames(
				'fixed fade-in top-0 right-0 bottom-0 left-0 bg-center bg-cover z-30 flex flex-col items-center content-stretch pt-32',
				fadeOut && 'fade-out'
			)}
			style={{ backgroundImage: `url("${mobileBackground}")` }}
		>
			{menuItems.map((item, index, array) => (
				<NavLink
					activeClassName="font-medium"
					to={item.link}
					key={index}
					className={classNames(
						'font-cera text-white px-4 font-light text-2xl py-4 my-4 opacity-0 fade-in',
						index === 0 && 'mt-auto',
						index === array.length - 1 && 'mb-auto'
					)}
					style={{ animationDelay: `${(index + 1) * 100}ms` }}
				>
					{getMessage(item.title)}
				</NavLink>
			))}
		</div>
	);
};
