import { useLang } from 'state/LanguageContext/LanguageContext';
import { IFormInputs } from './form';

type IUseConfigReturnType = {
	name: keyof IFormInputs;
	label: string;
	placeholder: string;
	textarea?: boolean;
	numberOfRows?: number | undefined;
}[];

export const useConfig = (): IUseConfigReturnType => {
	const { getMessage } = useLang();

	return [
		{
			name: 'fullName',
			textarea: false,
			label: getMessage('contact_fullName_label'),
			placeholder: getMessage('contact_fullName_placeholder'),
		},
		{
			name: 'email',
			textarea: false,
			label: getMessage('contact_email_label'),
			placeholder: getMessage('contact_email_placeholder'),
		},
		{
			name: 'phoneNumber',
			textarea: false,
			label: getMessage('contact_phoneNumber_label'),
			placeholder: getMessage('contact_phoneNumber_placeholder'),
		},
		{
			name: 'message',
			textarea: true,
			label: getMessage('contact_message_label'),
			placeholder: getMessage('contact_message_placeholder'),
		},
	];
};
