import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import classes from './Logo.module.css';

interface LogoProps {
	className?: string;
}

export const Logo: FunctionComponent<LogoProps> = ({ className }) => (
	<Link to={'/'}>
		<div className={classNames('w-20 h-20 bg-contain bg-center bg-no-repeat', classes.logo, className)}></div>
	</Link>
);
