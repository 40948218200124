export interface IProjectForm {
	title: string;
	address: string;
	airbnblink: string;
	content: string;
	description: string;
	galerylabel: string;
	landing: string;
}

export const useConfig = () => {
	return [
		{
			name: 'title.ro',
			label: 'Title Romana',
			required: true,
			default: '',
		},
		{
			name: 'title.en',
			label: 'Title English',
			required: true,
			default: '',
		},
		{
			name: 'address.ro',
			label: 'Address Romana',
			required: true,
			default: '',
		},
		{
			name: 'address.en',
			label: 'Address English',
			required: true,
			default: '',
		},
		{
			name: 'photoUrl',
			label: 'Main Photo URL',
			default: '',
		},
		{
			name: 'airBnbLink',
			label: 'AirBnb link',
			default: '',
		},
		{
			name: 'content.ro',
			label: 'Content Romana',
			type: 'html',
			default: '',
		},
		{
			name: 'content.en',
			label: 'Content English',
			type: 'html',
			default: '',
		},
		{
			name: 'description.ro',
			label: 'Description Romana',
			default: '',
		},
		{
			name: 'description.en',
			label: 'Description English',
			default: '',
		},
		{
			name: 'gallerylabel.ro',
			label: 'Galery label Romana',
			type: 'dropdown',
			options: [
				{ value: 'înainte & după', label: 'înainte & după' },
				{ value: 'galerie', label: 'galerie' },
			],
			default: 'galerie',
		},
		{
			name: 'gallerylabel.en',
			label: 'Galery label English',
			type: 'dropdown',
			options: [
				{ value: 'before & after', label: 'before & after' },
				{ value: 'gallery', label: 'gallery' },
			],
			default: 'gallery',
		},
		{
			name: 'landing',
			label: 'Is landing page',
			type: 'checkbox',
			default: false,
		},
		{
			name: 'featured',
			label: 'Is featured on the first page',
			type: 'checkbox',
			default: false,
		},
		{
			name: 'projectsPage',
			label: 'Appears on projects page',
			type: 'checkbox',
			default: true,
		},
	];
};
