import { useMemo } from 'react';
import { AvailableLanguages } from 'state/LanguageContext/LanguageContext';
import { RequestState } from './useFetcher';

const parseObject = (object: Record<string, unknown>, lang: AvailableLanguages): Record<string, unknown> => {
	const returnedObject = {} as Record<string, unknown>;
	// maybe the object is not really on object
	if (typeof object !== 'object') {
		return object;
	}
	// Parse each entry of the object
	for (let entry of Object.entries(object)) {
		// maybe the value in the object is an array
		if (Array.isArray(entry[1])) {
			// parse each array entry individually
			returnedObject[entry[0]] = entry[1].map((arrayEntry) => parseObject(arrayEntry as Record<string, unknown>, lang));
			continue;
		}
		// If the value is object
		if (typeof entry[1] === 'object' && entry[1] !== null) {
			const langEntry = (entry[1] as Record<string, unknown>)[lang];
			// If the object contains the language property
			if (langEntry !== null && langEntry !== undefined) {
				// Then replace the object with the string in that language
				returnedObject[entry[0]] = langEntry;
			} else {
				// Otherwise, it means we are dealing with a regular object
				// so the entire function is repeated for that particular object
				returnedObject[entry[0]] = parseObject(entry[1] as Record<string, unknown>, lang);
			}
			// If the value is not an object we won't modify it
		} else {
			returnedObject[entry[0]] = entry[1];
		}
	}
	return returnedObject;
};

export const useLanguageResponseParser = <T>(state: RequestState<T[]>, lang: AvailableLanguages) => {
	return useMemo(() => {
		if (!state.response) {
			return state;
		}
		if (Array.isArray(state.response)) {
			return {
				...state,
				response: (state.response.map((responseEntry) =>
					parseObject(responseEntry as Record<string, unknown>, lang)
				) as unknown) as T[],
			};
		}
		return { ...state, response: parseObject(state.response as Record<string, unknown>, lang) as T };
	}, [state, lang]);
};
