import { FunctionComponent } from 'react';
import { InputPropTypes } from './Input.props';
import classNames from 'classnames';

export const Input: FunctionComponent<InputPropTypes> = ({
	placeholder,
	label,
	errorMessage,
	className,
	multiline = false,
	numberOfRows,
	value,
	type,
	onBlur,
	onChange,
}) => {
	return (
		<div className="">
			<p className="text-xl pb-2 font-cera font-normal ">{label}</p>
			{multiline ? (
				<textarea
					className={classNames(
						'w-full rounded-lg py-2 px-3 text-lg font-cera font-normal bg-gray-100 focus:outline-none focus:ring ring-gray-50 resize-none h-52',
						className
					)}
					placeholder={placeholder}
					rows={numberOfRows}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
				></textarea>
			) : (
				<input
					className={classNames(
						'w-full rounded-lg py-2 px-3 text-lg font-cera font-normal bg-gray-100 focus:outline-none focus:ring ring-gray-50',
						className
					)}
					type={type}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
				/>
			)}

			<p className="text-sm text-red-500 pt-2 pl-3 h-9">{errorMessage}</p>
		</div>
	);
};
