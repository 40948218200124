import { useEffect, useState } from 'react';

export interface RequestState<T> {
	loading: boolean;
	error?: Error;
	response?: T;
}

export function useFetcher<T>(
	initialRequest: Promise<T> | T,
	initialValue: T
): RequestState<T> & { setRequest: (request: Promise<T> | T) => void } {
	const [request, setRequest] = useState(initialRequest);
	const [state, setState] = useState<RequestState<T>>({
		loading: false,
	});

	useEffect(() => {
		setRequest(initialRequest);
	}, [initialRequest]);

	useEffect(() => {
		let unmounted = false;

		async function loadData(): Promise<void> {
			try {
				setState((state) => ({ ...state, error: undefined, loading: true }));
				const response = (await request) || initialValue;

				if (!unmounted) {
					setState((state) => ({ ...state, response, loading: false }));
				}
			} catch (error) {
				if (!unmounted) {
					setState((state) => ({ ...state, error, loading: false }));
				}
			}
		}
		if (request) {
			loadData();
		}

		return function cancel() {
			unmounted = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [request]);

	return { ...state, setRequest };
}
