import { Button, Page } from 'components';
import { useGlobalState } from 'modules/StateProvider/StateProvider';
import React from 'react';
import { NavLink, Link } from 'react-router-dom';

export const SideContainer = () => {
	const { projects } = useGlobalState();

	if (projects && !projects.length) {
		return <p>No projects found</p>;
	}

	return (
		<Page className="bg-white rounded-tl-md rounded-bl-md border-l border-t border-b border-gray-200 h-screen overflow-auto relative shadow-inner">
			<div className="font-bebas font-bold text-4xl text-center sticky top-0 p-3 bg-white shadow-md ">
				Projects list
				<Link to="/dashboard">
					<Button theme="dark" label="create new project" className="my-3 mx-auto shadow-md" />
				</Link>
			</div>
			<div>
				{projects.map((project) => (
					<NavLink
						to={`/dashboard/${project.id}`}
						activeClassName="bg-gray-300"
						key={project.id}
						className="bg-white shadow-md p-3 m-4 cursor-pointer rounded-md grid grid-cols-12 border border-gray-200 transition-all duration-100 hover:bg-gray-100"
						onClick={() => console.log(project)}
					>
						<div
							style={{ backgroundImage: `url("${project.photoUrl}")` }}
							className="col-start-1 col-end-3 w-full h-0 p-full bg-cover rounded-full "
						></div>
						<p className="font-cera font-normal col-start-5 col-end-13 my-auto pl-2">{project.title}</p>
					</NavLink>
				))}
			</div>
		</Page>
	);
};
