import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

interface PageProps {
	className?: string;
}

export const Page: FunctionComponent<PageProps> = ({ children, className }) => {
	return <div className={classNames('min-h-screen bg-fixed', className)}>{children}</div>;
};
