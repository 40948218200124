import classNames from 'classnames';
import { useMediaQuery } from 'helpers/useMediaQuery';
import React, { PropsWithChildren, ReactNode, useEffect, useState } from 'react';

type GridLimits = 1 | 2 | 3 | 4 | 6 | 12;

const GRID_MAX_ITEMS = 12;

interface GridProps<T> {
	/** A list of items to be arranged in the grid */
	items: T[];
	/** Render function for each element */
	render: (prop: T & { column: number; columnCount: number }) => ReactNode;
	/** Class to be applied to the top level element */
	className?: string;
	/** Class to be applied on the column element */
	columnClassName?: string;
	/** Number of columns to be applied on small devices */
	sm?: GridLimits;
	/** Number of columns to be applied on medium devices */
	md?: GridLimits;
	/** Number of columns to be applied on large devices */
	lg?: GridLimits;
	/** Number of columns to be applied on extra large devices */
	xl?: GridLimits;
	/** Number of columns to be applied on giant screens */
	xxl?: GridLimits;
}

export const Grid = <T,>({
	items,
	render,
	sm = 12,
	md = sm,
	lg = md,
	xl = lg,
	xxl = xl,
	columnClassName,
}: PropsWithChildren<GridProps<T>>) => {
	const [columns, setColumns] = useState([items]);

	const selectedQuery = useMediaQuery({ queries: { sm, md, lg, xl, '2xl': xxl } });

	useEffect(() => {
		if (!selectedQuery) {
			return;
		}
		const formattedColumns = items.reduce((previous, current, index) => {
			const newIndex = (index + 1) % (12 / selectedQuery);
			if (!previous[newIndex]) {
				previous[newIndex] = [];
			}
			previous[newIndex].push(current);
			return previous;
		}, [] as T[][]);
		setColumns(formattedColumns);
	}, [selectedQuery, items]);

	return (
		<div className={`w-full grid grid-cols-${GRID_MAX_ITEMS / selectedQuery} gap-12`}>
			{columns.map((column, index) => (
				<div className={classNames(columnClassName)} key={index}>
					{column.map((item) => render({ ...item, column: index + 1, columnCount: columns.length }))}
				</div>
			))}
		</div>
	);
};
