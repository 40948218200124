import classNames from 'classnames';
import React, { Component } from 'react';
import './Carousel.css';

export class Carousel extends Component<any> {
	imgs: string[];
	state: any;
	carouselContainerWidth: number = 0;
	carouselElement: any;

	constructor(props: any) {
		super(props);
		this.state = { isSliding: false, isAnimating: false, currentImageIndex: this.props.defaultIndex || 0 };
		this.prevPhoto = this.prevPhoto.bind(this);
		this.nextPhoto = this.nextPhoto.bind(this);
		this.imgs = this.cloneImages(this.props.imgs);
	}
	cloneImages(imgs: string[]) {
		const firstImage = imgs[0];
		const lastImage = imgs[imgs.length - 1];
		return [lastImage, ...imgs, firstImage];
	}
	enableButtons() {
		const { transitionDuration } = this.props;
		setTimeout(() => {
			this.setState({
				isSliding: false,
			});
		}, transitionDuration);
	}
	disableButtons() {
		this.setState({
			isSliding: true,
		});
		this.enableButtons();
	}
	isTheLastImage(direction: 'next' | 'prev') {
		const imagesCount = this.props.imgs.length;
		if (direction === 'next' && this.state.currentImageIndex >= imagesCount) {
			return true;
		} else if (direction === 'prev' && this.state.currentImageIndex <= 1) {
			return true;
		}
		return false;
	}

	moveSliderContainer(direction = 'next') {
		if (this.state.isSliding) {
			return;
		}
		const imagesCount = this.props.imgs.length;
		const currentImageIndex =
			direction === 'next' ? this.state.currentImageIndex + 1 : this.state.currentImageIndex - 1;
		const { transitionDuration } = this.props;

		this.setState({ currentImageIndex, isAnimating: true });

		if (this.isTheLastImage(direction as 'next' | 'prev')) {
			setTimeout(() => {
				const currentImageIndex = direction === 'next' ? 1 : imagesCount;
				this.setState({ currentImageIndex, isAnimating: false });
			}, transitionDuration);
		}
		this.disableButtons();
	}

	nextPhoto() {
		this.moveSliderContainer('next');
	}
	prevPhoto() {
		this.moveSliderContainer('prev');
	}
	componentDidMount() {
		this.carouselContainerWidth = this.carouselElement.offsetWidth;
		this.setState({ currentImageIndex: (this.props.defaultIndex || 0) + 1 });
	}
	render() {
		const { transitionDuration } = this.props;
		const sliderTransition = this.state.isAnimating ? `${transitionDuration}ms` : '0ms';
		const imgsElements = this.imgs.map((src, i) => (
			<div
				onClick={() => this.props?.onImageClick?.(i)}
				className={this.props.photoClassName}
				style={{
					backgroundImage: `url("${src}")`,
					height: '100vh',
					width: '100vw',
					position: 'relative',
					display: 'inline-block',
					flexShrink: 0,
					backgroundSize: 'cover',
					backgroundPosition: 'center center',
				}}
				key={i}
			/>
		));
		const disabledClass = this.state.isSliding ? 'disabled' : '';
		const sliderLeftPosition = -1 * (this.state.currentImageIndex || 0) * (this.carouselContainerWidth || 0);
		return (
			<div className={classNames('carousel', this.props.className)} ref={(ref) => (this.carouselElement = ref)}>
				<div
					className="slider h-screen whitespace-nowrap"
					style={{
						left: sliderLeftPosition,
						transitionDuration: sliderTransition,
						width: this.props.imgs.length * this.carouselContainerWidth,
					}}
				>
					{imgsElements}
				</div>
				<button
					className={`prev ml-6 md:ml-12 ${disabledClass} ${this.props.buttonsClassName}`}
					onClick={this.prevPhoto}
				></button>
				<button
					className={`next mr-6 md:mr-12 ${disabledClass} ${this.props.buttonsClassName}`}
					onClick={this.nextPhoto}
				></button>
			</div>
		);
	}
}
