import { Project } from 'modules/Projects/types';
import React, { FunctionComponent } from 'react';
import Scrollspy from 'react-scrollspy';

import './LandingPageSpy.css';

interface LandingPageSpyProps {
	projects: Project[];
}

export const LandingPageSpy: FunctionComponent<LandingPageSpyProps> = ({ projects }) => {
	return (
		<div className="fixed right-4 top-0 bottom-0 items-center z-10 hidden sm:flex">
			<Scrollspy items={projects.map((project) => project.id)} currentClassName="current">
				{projects.map((project, index) => (
					<li key={index} className="relative right-4 md:right-14 mt-10">
						<a href={`#${project.id}`} className="cursor-pointer flex items-center justify-center w-4 h-4">
							<div className="absolute circle bg-white w-2 h-2 bg-opacity-40 rounded-full duration-300 top-0 right-0 bottom-0 left-0 m-auto"></div>
							<div className="text absolute right-10 text-white  opacity-0 transition-all duration-300 font-cera font-light text-2xl top-0 bottom-0 transform -translate-y-1.5">
								{index + 1}
							</div>
						</a>
					</li>
				))}
			</Scrollspy>
		</div>
	);
};
