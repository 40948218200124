import { Logo } from 'components';
import React from 'react';
import { Form } from './form';
import classes from './LoginPage.module.css';
import classNames from 'classnames';

export const LoginPage = () => {
	return (
		<main
			className={classNames(
				classes.background,
				'min-h-screen bg-cover bg-center lg:flex-row justify-around items-center grid grid-cols-12'
			)}
		>
			<div className="flex flex-col p-4 fade-in bg-white rounded-xl col-start-2 col-end-7 ">
				<p className="font-bebas text-6xl text-black text-center pt-6 font-bold">LOG IN</p>
				<Form />
			</div>
			<div className="col-start-10 col-end-12">
				<Logo className="w-40 h-40 md:w-56 md:h-56" />
			</div>
		</main>
	);
};
