import { NavBar, Page } from 'components';
import { Footer } from 'modules/Footer/Footer';
import { useGlobalState } from 'modules/StateProvider/StateProvider';
import React, { FunctionComponent } from 'react';

import { LandingPageSection } from './LandingPageSection';
import { LandingPageSpy } from './LandingPageSpy';

export const LandingPage: FunctionComponent = () => {
	const { projects } = useGlobalState();
	const featuredProjects = projects.filter((project) => project.featured);
	const landingProject = projects.find((project) => project.landing);
	return (
		<Page className="bg-black">
			<NavBar />
			{landingProject && <LandingPageSection project={landingProject} landing />}
			{featuredProjects.map((project, index) => (
				<LandingPageSection key={project.id} project={project} index={index} />
			))}
			{landingProject && <LandingPageSpy projects={[landingProject, ...featuredProjects]} />}
			<Footer />
		</Page>
	);
};
