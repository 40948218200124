import { useFirestoreCollection } from 'helpers/useFirestoreCollection';
import { Footer } from 'modules/Footer/types';
import { IProfile } from 'modules/ProfilePage/ProfilePage';
import { Project } from 'modules/Projects/types';
import React, { createContext, FunctionComponent, useContext } from 'react';

import classes from './Spinner.module.css';

interface IAbout {
	content: string;
}

export const StateContext = createContext({
	rawProjects: [] as Project[] | undefined,
	projects: [] as Project[],
	footer: {} as Footer,
	metadata: [] as IAbout[],
	profiles: [] as IProfile[],
});

export const useGlobalState = () => useContext(StateContext);

export const StateProvider: FunctionComponent = ({ children }) => {
	const { response: projects, loading: projectsLoading, raw } = useFirestoreCollection<Project>('projects');
	const { response: footers, loading: footerLoading } = useFirestoreCollection('footer');
	const { response: metadata, loading: metadataLoading } = useFirestoreCollection('metadata');
	const { response: profiles, loading: profilesLoading } = useFirestoreCollection('profiles');

	if (projectsLoading || footerLoading || metadataLoading || profilesLoading) {
		return (
			<div className="absolute top-0 right-0 bottom-0 left-0 bg-white flex items-center">
				<svg className={classes.spinner} viewBox="0 0 50 50">
					<circle className={classes.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
				</svg>
			</div>
		);
	}

	return (
		<>
			<StateContext.Provider
				value={{
					rawProjects: raw,
					projects: (projects || []) as Project[],
					footer: footers?.[0] || {},
					metadata: metadata || [],
					profiles: profiles || [],
				}}
			>
				{children}
			</StateContext.Provider>
		</>
	);
};
