import { getRandomNumber } from 'helpers/getRandomNumber';
import { Project } from 'modules/Projects/types';
import React, { FunctionComponent, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { getProjectLink } from 'helpers/getProjectLink';

const calculateProjectTransition = (column: number, columnCount: number): string => {
	if (columnCount > 1 && column === 1) {
		return 'fade-to-right';
	}
	if (columnCount > 1 && column === columnCount) {
		return 'fade-to-left';
	}
	return 'fade-in';
};

export const ProjectCard: FunctionComponent<Project & { column: number; columnCount: number }> = ({
	photoUrl,
	title,
	id,
	column,
	columnCount,
}) => {
	const cardHeight = useRef(getRandomNumber(200, 400));
	const { push } = useHistory();
	return (
		<article
			className={classNames('w-full mt-10 cursor-pointer', calculateProjectTransition(column, columnCount))}
			key={title}
			onClick={() => push(getProjectLink({ id, title }))}
		>
			<div
				className="w-full bg-cover bg-center rounded-xl transition-opacity duration-200 hover:opacity-80"
				style={{ height: `${cardHeight.current}px`, backgroundImage: `url("${photoUrl}")` }}
			/>
			<h2 className="font-bebas text-4xl py-6 px-2">{title}</h2>
		</article>
	);
};
