import { Page } from 'components';
import { useGlobalState } from 'modules/StateProvider/StateProvider';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FormProject } from './Form';

export const Main = () => {
	const { rawProjects } = useGlobalState();
	const { id } = useParams<{ id: string }>();

	const selectedProject = useMemo(() => rawProjects?.find((project) => project.id === id), [rawProjects, id]);

	return (
		<Page className="bg-white border-t border-r border-b border-gray-200 rounded-tr-md rounded-br-md p-8 h-screen overflow-auto">
			<p className="font-bebas font-bold text-5xl text-black">
				{selectedProject?.title ? <>{(selectedProject?.title as any).en}</> : 'New Project'}
			</p>
			{selectedProject?.id && <span className="text-xs bg-black text-white rounded-lg p-2">{selectedProject?.id}</span>}
			<FormProject className="m-4 pt-8" defaultProjectValues={selectedProject} key={selectedProject?.id || 'new'} />
		</Page>
	);
};
