import { BackButton, Logo } from 'components';
import React, { FunctionComponent } from 'react';

export const GoBackHeader: FunctionComponent<{ to?: string }> = ({ to }) => {
	return (
		<div className="w-full flex absolute top-0 justify-between px-7 sm:px-16 py-12">
			<div className="flex items-center sm:items-start">
				<BackButton to={to} className="h-8 mt-3" />
			</div>
			<Logo className="hidden sm:block" />
		</div>
	);
};
