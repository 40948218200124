import { Page } from 'components';
import classNames from 'classnames';
import { useLang } from 'state/LanguageContext/LanguageContext';

import classes from './AboutUsPage.module.css';
import React from 'react';
import { Footer } from 'modules/Footer/Footer';
import { GoBackHeader } from 'components/NavBar/GoBackHeader';
import { useGlobalState } from 'modules/StateProvider/StateProvider';

export const AboutUsPage = () => {
	const { getMessage } = useLang();
	const { metadata } = useGlobalState();

	return (
		<Page className={classNames(classes.background, 'bg-cover min-h-screen flex flex-col')}>
			<GoBackHeader />
			<div className=" w-screen flex-grow">
				<div className="grid grid-cols-12 mb-32">
					<div className="col-start-1 sm:col-start-2 col-end-13 sm:col-end-12 p-3">
						<p className="text-white font-bebas font-bold text-4xl md:text-6xl mt-32 mb-8">
							{getMessage('about_us_title')}
						</p>
						<div className="bg-white rounded-3xl">
							<div className="w-full px-12 py-10">
								<div dangerouslySetInnerHTML={{ __html: metadata[0]?.content }} className="font-cera"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer className="mt-auto mb-0 flex-grow-0" />
		</Page>
	);
};
