import classNames from 'classnames';
import { BackButton } from 'components';
import { getProjectLink } from 'helpers/getProjectLink';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'state/LanguageContext/LanguageContext';
import { useProject } from './ProjectContext';

export const ProjectDescription: FunctionComponent = () => {
	const { project, previousProject, nextProject } = useProject();
	const hashSocialLink = Object.keys(project?.social || {}).length > 0;
	return (
		<div className="w-full flex flex-col px-7 md:px-10 lg:px-20 pb-6 md:pb-10 lg:pb-16">
			<div
				className="font-cera font-regular text-sm md:text-xl leading-relaxed md:leading-tight"
				dangerouslySetInnerHTML={{ __html: project?.content || '' }}
			></div>
			{hashSocialLink && (
				<div className="flex flex-col  pt-6 md:pt-10 lg:pt-16">
					<div className="font-cera font-medium block uppercase">
						<FormattedMessage id="follow " />
						{project.title}
						<FormattedMessage id=" on" />:
					</div>
					<div className="flex justify-left mt-8">
						{Object.entries(project.social).map((socialEntry) => (
							<a href={socialEntry[1]} key={socialEntry[0]} target="_new">
								<div className={classNames('w-10 h-10 mr-10', `${socialEntry[0]}-icon`)}></div>
							</a>
						))}
					</div>
				</div>
			)}
			<div className="flex justify-between mt-24 md:mt-10 mb-16 md:mb-11 lg:pt-16">
				<div>
					{previousProject && (
						<BackButton
							theme="dark"
							labelId="previous-project"
							to={getProjectLink(previousProject)}
							onClick={() => {}}
							className="flex underline md:no-underline text-sm md:text-2xl font-medium"
							arrowClassName="hidden md:inline-block"
						/>
					)}
				</div>
				<div>
					{nextProject && (
						<BackButton
							className="flex flex-row-reverse underline md:no-underline text-sm md:text-2xl font-medium"
							arrowClassName="transform rotate-180 ml-3.5 hidden md:inline-block"
							theme="dark"
							labelId="next-project"
							to={getProjectLink(nextProject)}
							onClick={() => {}}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
