import { Page } from 'components';
import { Footer } from 'modules/Footer/Footer';
import React from 'react';
import { Form } from './form';
import classes from './ContactPage.module.css';
import classNames from 'classnames';
import { useLang } from 'state/LanguageContext/LanguageContext';
import { GoBackHeader } from 'components/NavBar/GoBackHeader';

export const ContactPage = () => {
	const { getMessage } = useLang();
	return (
		<Page className={classNames(classes.background, 'bg-cover')}>
			<GoBackHeader />
			<div className=" w-screen">
				<div className="grid grid-cols-12 mb-32">
					<div className="col-start-1 sm:col-start-2 xl:col-start-3 col-end-13 sm:col-end-12 xl:col-end-11 py-3 px-3 md:px-10 xl:px-24">
						<p className="text-white font-bebas font-bold text-4xl md:text-6xl mt-32 mb-8">
							{getMessage('contact_title')}
						</p>
						<div className="bg-white rounded-3xl py-1">
							<div className="px-6 sm:px-24 mx-2 py-16">
								<Form />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer className="mt-auto" />
		</Page>
	);
};
